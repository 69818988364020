import React from 'react';
import axios from "axios";
import mixpanel from 'mixpanel-browser';



class MyForm extends React.Component {

    constructor() {
        super();
        this.state = {
            name: '',
            email: '',
            business: '',
            message: '',
            submitted: false,

        };
    }

    componentDidMount() {
        mixpanel.track("hello")
        console.log("hello")
    }

    onChange = (e) => {
        /*
          Because we named the inputs to match their
          corresponding values in state, it's
          super easy to update the state
        */
        this.setState({ [e.target.name]: e.target.value });

        console.log("UPDATING")
    }

    onSubmit = (e) => {
        e.preventDefault();
        mixpanel.track('contact_form'); // send event 'contact_form' to mixpanel
        this.setState({ submitted: true })
        const { name, email, business, message } = this.state;
        console.log(this.state)
        axios.post('https://cors-anywhere.herokuapp.com/https://izlhxow80d.execute-api.us-west-1.amazonaws.com/default/lamora_contact_submission',
            { name, email, business, message }).then(response => {
                console.log("submitted form")
            }).then(response => { console.log("thank you") }).then(response => { console.log("axios responded") })
    }



    render() {
        const { name, email, business, message, submitted } = this.state;
        let thing;

        if (submitted) {
            thing =
                <form id="contactform">
                    <h2>Thank you!</h2>
                    <h4>We will reach out to you soon.</h4>
                </form >


        } else {
            // example here: https://colorlib.com/wp/template/colorlib-contact-form/
            thing =
                <form onSubmit={this.onSubmit} id="contactform">

                    <h3>Let's get started...</h3>
                    <fieldset>
                        <input type="text" className="form-control" id="name" placeholder="Name" name="name" value={name} onChange={this.onChange} />
                    </fieldset>
                    <fieldset>
                        <input type="email" className="form-control" id="email" placeholder="email@address.com" name="email" value={email} onChange={this.onChange} />
                    </fieldset>
                    <fieldset>
                        <input type="text" className="form-control" id="business" placeholder="Business Name" name="business" value={business} onChange={this.onChange} />
                    </fieldset>
                    <fieldset>
                        <textarea className="form-control" id="message" rows="4" placeholder="Describe your project..." name="message" value={message} onChange={this.onChange} />

                    </fieldset>
                    <button type="submit">Submit</button>
                </form >

        }

        return (
            <div className="container" >
                {thing}
            </div>
        );
    }
}

export default MyForm;
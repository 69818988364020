import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "lamora_clock" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "lamora_bars" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "lamora_diamond" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section style={{ padding: "120px 0px 20px 0px" }} id="about" >
        <Container>
          <h2 className="tagline">Studio-quality product photos with models at affordable prices</h2>
          <br></br>
          <br></br>
          <br></br>


          <Grid>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>

            <div>
              <h2>Increase Conversion</h2>
              <p>
                High quality photos with models are shown to increase conversion by 30% on average              </p>
            </div>

            <div>
              <h2>Save Time</h2>
              <p>
                We'll handle everything. All you do is tell us what you want and ship your products
              </p>
            </div>

            <div>
              <h2>Show Off</h2>
              <p>
                Our models and photographers are best in class. We'll make sure your products will be seen in their best light              </p>
            </div>
          </Grid>
        </Container>
      </Section >
    )}
  />
);



const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  grid-gap: 40px;
  text-align: center;
  align-items: start;
  justify-items: center;
  margin: 24px 0;

  div {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 0px;

    &:last-child {
      margin-bottom: 0px;
    }

  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 200px;
  max-height: 200px;
  width: 100%;
`;

export default About;

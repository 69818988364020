import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';

const FAQS = [

  {
    title: 'Do you do photographs of just products?',
    content: () => (
      <>
        Yes! In addition to photographs with models, we also take blank background product photos.
      </>
    ),
  },
  {
    title: 'Do you do lifestyle product photography?',
    content: () => (
      <>
        Currently we are focused on blank-background product photography with and without models. But we may expand to lifestyle photography in the future!
      </>
    ),
  },
  {
    title: 'Are your images edited?',
    content: () => (
      <>
        Yes. All of our images are edited/retouched by professionals. But we can send you the raw photos if you like, too!
      </>
    ),
  },
  {
    title: 'How much does it cost?',
    content: () => (
      <>
        We take advantage of scale to lower costs to our partners. Send us a message with your product info and we'll get back with a quote.
      </>
    ),
  },

];

const Faq = () => (
  <Section style={{ padding: "0px 0px 100px 0px" }} id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);




export default Faq;

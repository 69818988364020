import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import About from '@sections/About';
import HowItWorks from '@sections/HowItWorks';
import Brands from '@sections/Brands';
import Team from '@sections/Team';
import Contact from '@sections/Contact';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';
import FormPage from '../components/sections/BContact';
import _ from '../mixpanel.js'


const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    {/* <HowItWorks /> */}
    <About />
    {/* <Brands /> */}
    {/* <Team /> */}
    <Contact />
    <Faq />
    {/* <FormPage /> */}
    <Footer />
  </Layout>
);

export default IndexPage;
